<template>
  <v-container
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-card-title>
            Acertos Virtuais
            <v-spacer />
            <v-text-field
              v-model="filter.Term"
              label="Buscar (nome)"
              single-line
              hide-details
              class="mr-5"
              @keyup.enter="load"
            />
            <v-text-field
              v-model="filter.CodigoStyllus"
              label="Buscar (código styllus)"
              single-line
              hide-details
              type="number"
              @keyup.enter="load"
            />
            <v-btn
              color="primary"
              class="mt-4 ml-4"
              @click="load"
            >
              Buscar
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="acertos"
            :options.sync="options"
            :server-items-length="total"
            :footer-props="footer_props"
            class="elevation-1"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.acertoVirtualId"
                >
                  <td>{{ item.cliente.pessoa.nome }}</td>
                  <td>{{ item.cliente.styllusRevendedorId }}</td>
                  <td>{{ formater.format(item.valorTotal) }}</td>
                  <td>{{ item.desconto }}%</td>
                  <td>{{ formater.format(item.valorLiquido) }}</td>
                  <td>{{ item.transactionId || '-' }}</td>
                  <td>{{ item.statusPagamentoTexto }}</td>
                  <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <v-card-actions class="acoes d-print-none">
            <v-btn
              color="success"
              dark
              @click="download()"
            >
              Download
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import mime from 'mime-types'

  export default {
    data: () => ({
      formater: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      acertos: [],
      headers: [
        { text: 'Nome', sortable: false },
        { text: 'Código Styllus', sortable: false },
        { text: 'Valor Total', sortable: false },
        { text: 'Desconto', sortable: false },
        { text: 'Valor Líquido', sortable: false },
        { text: 'Transaction ID', sortable: false },
        { text: 'Status', sortable: false },
        { text: 'Data', sortable: false },
      ],
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      total: 0,
      dialog: false,
      options: {
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        PerPage: 25,
        Page: 1,
        SortAscending: true,
      },
    }),
    watch: {
      options: {
        handler () {
          this.load()
        },
        deep: true,
      },
    },
    methods: {
      load () {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = this.options.page
        if (this.filter.CodigoStyllus === '' || this.filter.CodigoStyllus === null) {
          delete this.filter.CodigoStyllus
        }
        const query = JSON.stringify(this.filter)
        this.$http.get('/acerto', { params: { query } })
          .then(resp => {
            this.acertos = resp.data.rows
            this.total = resp.data.total
          })
      },
      forceFileDownload (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const filename = Math.round((new Date()).getTime() / 1000)
        link.setAttribute('download', `${filename}.${mime.extension(response.headers['content-type'])}`)
        document.body.appendChild(link)
        link.click()
      },
      download () {
        const query = JSON.stringify(this.filter)
        this.$http.get('/acerto/download', { params: { query }, responseType: 'arraybuffer' })
          .then(response => {
            this.forceFileDownload(response)
          })
      },
    },
  }
</script>
